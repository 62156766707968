import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useContentful } from "react-contentful";
import { useCookies } from "react-cookie";

function Menu() {
  const [open, setOpen] = useState(false);
  const [cookies, setCookie] = useCookies();
  const locale = cookies["locale"] ?? "sv-SE";

  const { data, error, fetched, loading } = useContentful({
    contentType: "navigation",
    locale,
    query: {
      "fields.referenceName": "Menu",
    },
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (!data) {
    return null;
  }

  const navigation = data.items?.[0];

  if (!navigation) {
    return null;
  }

  const changeLanguage = (newLocale) => {
    setCookie("locale", newLocale, {
      maxAge: 60 * 60 * 24 * 365,
    });
  };

  return (
    <nav className={classNames("topnav", { open })}>
      <ul>
        {navigation.fields.navigationItems.map((navigationItem) => {
          let slug = navigationItem.fields.page.fields.slug;
          if (slug === "start") {
            slug = "";
          }

          return (
            <li key={navigationItem.sys.id}>
              <Link to={"/" + slug}>{navigationItem.fields.name}</Link>
            </li>
          );
        })}

        {locale !== "en-US" && (
          <li className="language-button-in-menu">
            <a
              href={window.location.pathname}
              onClick={() => changeLanguage("en-US")}
            >
              In English
            </a>
          </li>
        )}
        {locale !== "sv-SE" && (
          <li className="language-button-in-menu">
            <a
              href={window.location.pathname}
              onClick={() => changeLanguage("sv-SE")}
            >
              På svenska
            </a>
          </li>
        )}
      </ul>
      <button
        className="icon menu-toggle"
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
      >
        <i className="fas fa-bars"></i>
      </button>
    </nav>
  );
}

export default Menu;
