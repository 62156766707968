import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { useContentful } from "react-contentful";
import { useCookies } from "react-cookie";
import Menu from "./Menu";
import LanguageSelector from "./LanguageSelector";

function Header() {
  const [cookies] = useCookies();
  const locale = cookies["locale"] ?? "sv-SE";

  const { data, error, fetched, loading } = useContentful({
    contentType: "header",
    locale,
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (!data) {
    return null;
  }

  const header = data.items[0];

  if (!header) {
    return null;
  }

  return (
    <header>
      <div></div>
      <div className="title-container">
        <div>
          <Link to="/">
            <h1 className="title">{header.fields.heading}</h1>
          </Link>
          <p className="slogan">{header.fields.slogan}</p>
        </div>
        <LanguageSelector />
      </div>
      <Menu />
    </header>
  );
}

export default Header;
