import React from "react";

const PropertyList = (props) => {
  return (
    <div className="columns-2">
      {props.fields.images.map((image) => {
        return (
          <div key={image.sys.id}>
            <img src={image.fields.file.url} alt={image.fields.file.title} />
          </div>
        );
      })}
    </div>
  );
};

export default PropertyList;
