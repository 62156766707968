import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer>
      <ul>
        <li>
          <i className="fas fa-map-marked-alt"></i>
          <span>Falekvarna Stenbrottet 2, 521 91 Falköping</span>
        </li>
        <li>
          <i className="fas fa-at"></i>
          <span>stenbrottet@gmail.com</span>
        </li>
        <li>
          <i className="fas fa-phone-square"></i>
          <span>+46 70 242 80 89</span>
        </li>
      </ul>
      <span className="copyright">
        Copyright © {new Date().getFullYear()}. All rights reserved.
      </span>
    </footer>
  );
}

export default Footer;
