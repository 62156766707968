import React from "react";
import PriceBox from "./PriceBox";

const PriceBoxList = (props) => {
  return (
    <div className="columns-2">
      {props.fields.priceBoxes.map((priceBox) => {
        return (
          <PriceBox fields={priceBox.fields} key={priceBox.fields.heading} />
        );
      })}
    </div>
  );
};

export default PriceBoxList;
