import React from "react";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useTracking } from "./utils/useTracking";
import { ContentfulClient, ContentfulProvider } from "react-contentful";

import Page from "./pages/Page";

function AppContent() {
  useTracking("UA-168229020-1");

  return (
    <>
      <Header />
      <main>
        <Switch>
          <Route path="/:slug*" component={Page} />
        </Switch>
      </main>
      <Footer />
    </>
  );
}

function App() {
  const contentfulClient = new ContentfulClient({
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  });

  return (
    <ContentfulProvider client={contentfulClient}>
      <Router>
        <AppContent />
      </Router>
    </ContentfulProvider>
  );
}

export default App;
