import React from "react";
import RichText from "./RichText";

const PriceBox = (props) => {
  return (
    <section>
      <h2>{props.fields.heading}</h2>
      <RichText content={props.fields.content} />
    </section>
  );
};

export default PriceBox;
