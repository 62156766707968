import React from "react";
import AccommodationBox from "./AccommodationBox";

const AccommodationBoxList = (props) => {
  return (
    <div className="columns-2">
      {props.fields.accommodationBoxes.map((accommodationBox) => {
        return (
          <AccommodationBox
            fields={accommodationBox.fields}
            key={accommodationBox.fields.heading}
          />
        );
      })}
    </div>
  );
};

export default AccommodationBoxList;
