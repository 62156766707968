import React from "react";

const Map = (props) => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16860.927937981673!2d13.677505793642451!3d58.11280316082824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465af169efc50f1d%3A0xea88178bc4c29de6!2sFalekvarna%20Stenbrottet%202%2C%20521%2091%20Falk%C3%B6ping!5e0!3m2!1ssv!2sse!4v1568991230444!5m2!1ssv!2sse"
      width="100%"
      height="450"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen=""
      title={props.fields.title}
    ></iframe>
  );
};

export default Map;
