import React from "react";
import Helmet from "react-helmet";
import { useContentful } from "react-contentful";
import RichText from "../components/RichText";
import { useCookies } from "react-cookie";

const Page = (props) => {
  const [cookies] = useCookies();
  const locale = cookies["locale"] ?? "sv-SE";

  const { data, error, fetched, loading } = useContentful({
    contentType: "page",
    locale,
    query: {
      "fields.slug": props.match.params.slug || "start",
    },
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (!data) {
    return <p>Page does not exist.</p>;
  }

  const page = data.items[0];

  if (!page) {
    return <p>Page does not exist.</p>;
  }

  return (
    <article>
      <Helmet>
        <title>{page.fields.pageTitle || "Stenbrottet"}</title>
        <meta name="description" content={page.fields.metaDescription || ""} />
      </Helmet>
      <RichText content={page.fields.content} />
    </article>
  );
};

export default Page;
