import React from "react";
import { useCookies } from "react-cookie";
import classNames from "classnames";

const LanguageSelector = (props) => {
  const [cookies, setCookie] = useCookies();
  const locale = cookies["locale"] ?? "sv-SE";

  const changeLanguage = (newLocale) => {
    setCookie("locale", newLocale, {
      maxAge: 60 * 60 * 24 * 365,
    });
  };

  return (
    <ul className="language-selector">
      <li className="language-selector-item">
        <button
          title="På svenska"
          disabled={locale === "sv-SE"}
          onClick={() => changeLanguage("sv-SE")}
          className={classNames("language-selector-button", {
            "language-selector-button--selected": locale === "sv-SE",
          })}
        >
          SV
        </button>
      </li>
      <li className="language-selector-item">
        <button
          title="In English"
          disabled={locale === "en-US"}
          onClick={() => changeLanguage("en-US")}
          className={classNames("language-selector-button", {
            "language-selector-button--selected": locale === "en-US",
          })}
        >
          EN
        </button>
      </li>
    </ul>
  );
};

export default LanguageSelector;
