import React from "react";
import RichText from "./RichText";

const AccommodationBox = (props) => {
  return (
    <section>
      <img
        src={props.fields.image.fields.file.url}
        alt={props.fields.image.fields.title}
      />
      <h3>{props.fields.heading}</h3>
      <RichText content={props.fields.description} />
    </section>
  );
};

export default AccommodationBox;
