import React from "react";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import AccommodationBox from "./AccommodationBox";
import AccommodationBoxList from "./AccommodationBoxList";
import PriceBox from "./PriceBox";
import PriceBoxList from "./PriceBoxList";
import PropertyList from "./PropertyList";
import ImageList from "./ImageList";
import Map from "./Map";

const RichText = (props) => {
  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return <a href={`/${node.data.target.fields.slug}`}>{children}</a>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return (
          <div>
            <img
              src={node.data.target.fields.file.url}
              alt={node.data.target.fields.title}
            ></img>
          </div>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const contentTypeId = node.data.target.sys.contentType.sys.id;
        const fields = node.data.target.fields;
        if (contentTypeId === "accommodationBoxList") {
          return <AccommodationBoxList fields={fields} />;
        }

        if (contentTypeId === "accommodationBox") {
          return <AccommodationBox fields={fields} />;
        }

        if (contentTypeId === "map") {
          return <Map fields={fields} />;
        }

        if (contentTypeId === "priceBoxList") {
          return <PriceBoxList fields={fields} />;
        }

        if (contentTypeId === "priceBox") {
          return <PriceBox fields={fields} />;
        }

        if (contentTypeId === "propertyList") {
          return <PropertyList fields={fields} />;
        }

        if (contentTypeId === "imageList") {
          return <ImageList fields={fields} />;
        }
      },
    },
  };

  // Process and pass in the loaded `data` necessary for your page or child components.
  return documentToReactComponents(props.content, options);
};

export default RichText;
