import React from "react";

const PropertyList = (props) => {
  return (
    <ul className="prop-list">
      {props.fields.properties.map((property) => {
        return (
          <li key={property.sys.id}>
            <span>{property.fields.name}</span>
            <span>{property.fields.value}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default PropertyList;
